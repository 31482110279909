/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import BundledPlayer from './bitmovin/BundledPlayer'
import { returnProgramContext } from '../ProgramContext'
import Button from '../extraComponents/button/Button'
import guideIcon from '../../../assets/images/guideIconSmall.svg'
import './player.scss'

const Player = ({
  isPipModeActive, getPipState, handleSpinner, handleSkeleton,
}) => {
  const {
    programInPlay,
    programInfo,
    alreadyPlayed,
    playedAd,
    alreadyUnmuted,
    setAlreadyUnmuted,
  } = returnProgramContext()
  const [isPhoneFullScreen, setIsPhoneFullScreen] = useState(false)
  const { t } = useTranslation('common')
  const liveConfig = {
    ui: true,
  }

  // handle player full screen
  useEffect(() => {
    const fullScreen = (requiresSpinner) => {
      const isFullScreen = (window.innerWidth > window.innerHeight
        && (window.innerWidth < 950 && window.innerHeight < 500))
      if (isFullScreen) {
        setIsPhoneFullScreen(true)
      } else {
        setIsPhoneFullScreen(false)
      }
    }

    fullScreen(false) // PREVENTS FLASHY BUG ON FIRST RENDER
    window.addEventListener('resize', () => fullScreen(true)) // Change resize on screen change
    return () => {
      window.removeEventListener('resize', () => fullScreen(true))
    }
  }, [])
  return (
    <div
          // eslint-disable-next-line no-nested-ternary
      className={isPipModeActive ? 'player-container-fixed'
        : isPhoneFullScreen ? 'player-container player-full-screen' : 'player-container'}
    >
      {/* <iframe src="https://live.appclicksgt.com/hybrid-stream-video-widget/canal27" allowFullScreen title="Iframe Example" /> */}
      <BundledPlayer
        isPipModeActive={isPipModeActive}
        src={programInPlay?.programVideo}
        initialMinute={programInPlay?.currentMinute}
        playerConfig={liveConfig}
        getPipStat={getPipState}
        title={programInfo?.name}
        poster={programInfo?.imageSrcThumbnail['16x9']}
        showAd={!alreadyPlayed.includes(programInPlay.id)}
        programId={programInPlay.id}
        playedAd={playedAd}
        setAlreadyUnmuted={setAlreadyUnmuted}
        alreadyUnmuted={alreadyUnmuted}
      />
      {isPipModeActive ? <Button onClick={() => getPipState(false)} icon={[guideIcon]} iconClass="guide-icon" iconAlt="Guide Icon" text={t('common.expand')} classButton="player-guide-button" /> : ''}
    </div>
  )
}

Player.propTypes = {
  isPipModeActive: PropTypes.bool,
  getPipState: PropTypes.func,
  handleSpinner: PropTypes.func,
  handleSkeleton: PropTypes.func,
}

Player.defaultProps = {
  isPipModeActive: false,
  getPipState: () => {},
  handleSpinner: () => {},
  handleSkeleton: () => {},
}

export default Player
